<template>
    <div
        class="ion"
        :class="ionClass"
        :title="iconTitle"
        name="icon-nav-left">
        <svg :width="w" :height="h" viewBox="0 0 32 32">
            <path d="M4.2925 15.2924L13.2925 6.29243C13.4801 6.10479 13.7346 5.99938 14 5.99938C14.2654 5.99938 14.5199 6.10479 14.7075 6.29243C14.8951 6.48007 15.0006 6.73457 15.0006 6.99993C15.0006 7.2653 14.8951 7.51979 14.7075 7.70743L7.41375 14.9999L27 14.9999C27.2652 14.9999 27.5196 15.1053 27.7071 15.2928C27.8946 15.4804 28 15.7347 28 15.9999C28 16.2651 27.8946 16.5195 27.7071 16.707C27.5196 16.8946 27.2652 16.9999 27 16.9999L7.41375 16.9999L14.7075 24.2924C14.8951 24.4801 15.0006 24.7346 15.0006 24.9999C15.0006 25.2653 14.8951 25.5198 14.7075 25.7074C14.5199 25.8951 14.2654 26.0005 14 26.0005C13.7346 26.0005 13.4801 25.8951 13.2925 25.7074L4.2925 16.7074C4.19952 16.6146 4.12576 16.5043 4.07544 16.3829C4.02512 16.2615 3.99921 16.1313 3.99921 15.9999C3.99921 15.8685 4.02512 15.7384 4.07544 15.617C4.12576 15.4956 4.19952 15.3853 4.2925 15.2924Z"/>
        </svg>
    </div>
</template>

<script>
import IoniconsMixin from './mixin'

export default {
    name: "icon-nav-back",
    mixins: [
        IoniconsMixin
    ],
    data() {
        let iconTitle = this.title ? this.title : ""
        return {
            iconTitle: iconTitle
        }
    }
}
</script>