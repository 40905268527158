<template>
    <transition-group tag="ul" class="action-bar" name="opacity">
        <li v-for="item in buttons" :key="item.slug" class="action-bar__item">
            <router-link :to="{name:'ContactDetails', params:{action:item.slug}}" :title="item.title"
                         class="action-bar__link"
                         v-bind:class="'--'+item.slug">
                <component v-if="item.icon" :is="'icon-'+item.icon" :title="item.title"></component>
            </router-link>
        </li>
        <slot></slot>
    </transition-group>
</template>

<script>

export default {
    props: {buttons: [Array, Object]}
}
</script>

<style scoped>

</style>
