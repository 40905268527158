<template>
    <router-link to="/">
        <transition name="opacity" mode="out-in" transitionend>
            <div class="nav-backlink">
                <icon-nav-back/>
            </div>
        </transition>
    </router-link>
</template>

<script>

import iconNavBack from "../icons/iconNavBack.vue";

export default {
    name: "NavBacklink",
    components: {iconNavBack}
}
</script>

<style scoped>
.nav-backlink {
    font-size: 32px;
    position: absolute;
    top: 32px;
    left: 32px;
    z-index: 5;
    color: white;
}
</style>