<template>
    <div class="fg-burger" :class="{'is-active':active}">
        <span class="fg-burger__icon">
            <span class="fg-burger__inner">
            <span class="fg-burger__before"></span>
            <span class="fg-burger__after"></span>
        </span>
        </span>
    </div>
</template>

<script>
export default {
    name: "burgerButton",
    props: {active: Boolean},
}
</script>

<style scoped>

</style>