<template>
    <TheNavigation :buttons="buttons">
        <li :key="'form'" class="action-bar__item">
            <router-link :to="hasClose?getLinkTo():{name:route.INFO}" :title="hasClose?'Close':'Overview'"
                         class="action-bar__link">
                <burger-button :active="hasClose"></burger-button>
            </router-link>
        </li>
    </TheNavigation>
</template>

<script>
import store from "../store";
import TheNavigation from "../components/TheNavigation";
import BurgerButton from "../components/burgerButton";
import overlayMixin from "../mixins/overlayMixin";
import route from '../router/constants'

export default {
    name: "Navbar",
    components: {BurgerButton, TheNavigation},
    props: {isHome: Boolean, hasClose: {type: Boolean, default: false}, hasButtons: {type: Boolean, default: false}},
    mixins: [overlayMixin],
    computed: {
        buttons() {
            if (this.hasButtons && this.$device.mobile)
                return store.contact

            if (this.hasClose)
                return [];

            if (!this.$device.mobile)
                return store.contact

            return []
        },
        form() {
            return store.form_id
        },
        route() {
            return route
        }
    }
}
</script>

<style scoped>

</style>